import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image/withIEPolyfill'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import ToggleComponentContext from '@providers/toggleComponentProvider'

const useStyles = makeStyles((theme) => ({
  gridItem: {
    cursor: 'pointer',
    '& .gatsby-image-wrapper': {
      height: '100%',
      minWidth: '100%',
      transition: 'transform 700ms ease-in-out',
    },
    '&:hover, &:focus': {
      '& .gatsby-image-wrapper': {
        transform: 'scale(1.05)',
      },
    },
  },
  gridItemImage: {
    overflow: 'hidden',
    border: `${theme.palette.grey.light} solid 1px`,
    height: '260px',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      height: '160px',
    },
    [theme.breakpoints.up('md')]: {
      height: '145px',
    },
    [theme.breakpoints.up('lg')]: {
      height: '137px',
    },
  },
  gridItemIntro: {
    fontSize: 18,
    fontWeight: 500,
    lineHeight: '23px',
    margin: 0,
    marginTop: theme.spacing(2),
  },
  mediaSlide: {
    border: 0,
    padding: 0,
    background: 'transparent',
    cursor: 'pointer',
    fontFamily: theme.typography.fontFamily,
    textAlign: 'left',
    width: '100%',
  },
}))

function MediaGrid({ className, files }) {
  const { toggleOverlay } = useContext(ToggleComponentContext)
  const classes = useStyles()

  function openDialog(mediaItem) {
    if (mediaItem.printMaterial) {
      toggleOverlay(true, 'displayPrintMaterial', 'default', {
        trackingID: 'dialog',
        printMaterial: mediaItem.printMaterial,
        width: 'xl',
      })
    } else {
      toggleOverlay(true, 'displayMedia', 'default', {
        trackingID: 'dialog',
        mediaItem,
      })
    }
  }

  function renderMediaGridItems() {
    if (!files?.length) return
    let list = []
    for (let i = 0; i < files.length; i++) {
      const item = files[i]
      let thumbnailFluid, alt, title

      if (item.printMaterial) {
        thumbnailFluid = item.printMaterial.images[0].fluid
        alt = item.printMaterial.images[0].description
        title = item.printMaterial.title
      } else {
        alt = item.description
        title = item.title

        if (!!item.thumbnail) {
          thumbnailFluid = { ...item.thumbnail, media: `(min-width: 0px)` }
        } else {
          thumbnailFluid = {
            aspectRatio: 1.7778,
            src: `https://img.youtube.com/vi/${item.youTubeKey}/maxresdefault.jpg`,
            srcSet: `https://img.youtube.com/vi/${item.youTubeKey}/maxresdefault.jpg`,
            sizes: '',
            media: `(min-width: 0px)`,
          }
        }
      }

      list.push(
        <Grid
          item={true}
          xs={12}
          sm={6}
          md={4}
          lg={3}
          key={i}
          data-testid="griditem"
          className={classes.gridItem}
        >
          <button
            className={classes.mediaSlide}
            onClick={() => {
              openDialog(item)
            }}
          >
            <div className={classes.gridItemImage}>
              <Img
                fluid={thumbnailFluid}
                alt={alt}
                backgroundColor="grey"
                objectFit={item.printMaterial ? 'contain' : 'cover'}
              />
            </div>
            <p className={classes.gridItemIntro}>{title}</p>
          </button>
        </Grid>
      )
    }
    return list
  }

  return (
    <>
      <Grid container spacing={8} className={className}>
        {renderMediaGridItems()}
      </Grid>
    </>
  )
}

MediaGrid.propTypes = {
  className: PropTypes.string,
  files: PropTypes.arrayOf(
    PropTypes.shape({
      createdAt: PropTypes.string,
      thumbnail: PropTypes.shape({
        fluid: PropTypes.object,
        description: PropTypes.string,
      }),
      file: PropTypes.object,
      slug: PropTypes.string,
      description: PropTypes.string,
      title: PropTypes.string,
      trackingTitle: PropTypes.string,
      youTubeKey: PropTypes.string,
      printMaterial: PropTypes.object,
      id: PropTypes.string,
    })
  ).isRequired,
}

export default MediaGrid
