import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Img from 'gatsby-image/withIEPolyfill'
import Icon from '@objects/icon'
import ToggleComponentContext from '@providers/toggleComponentProvider'
import Carousel from '@objects/carousel'

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: 'flex',
    position: 'relative',
  },
  carouselRoot: {
    '&.swiper-container': {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    },
    '& .swiper-slide': {
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'column',
      pointerEvents: 'auto',
      '& .gatsby-image-wrapper': {
        height: '100%',
        minWidth: '100%',
        transition: 'transform 700ms ease-in-out',
      },
      '&:hover, &:focus': {
        '& .gatsby-image-wrapper': {
          transform: 'scale(1.05)',
        },
      },
    },
  },
  navFraction: {
    cursor: 'pointer',
    zIndex: 1010,
    position: 'relative',
    width: theme.spacing(6),
    height: theme.spacing(6),
    marginTop: (props) => props.calculateArrowMarginTop(260, theme.spacing(6)),
    [theme.breakpoints.up('sm')]: {
      marginTop: (props) =>
        props.calculateArrowMarginTop(160, theme.spacing(6)),
    },
    [theme.breakpoints.up('md')]: {
      marginTop: (props) =>
        props.calculateArrowMarginTop(145, theme.spacing(6)),
    },
    [theme.breakpoints.up('lg')]: {
      marginTop: (props) =>
        props.calculateArrowMarginTop(137, theme.spacing(6)),
    },
    outline: 'none',
    '&:not([class*="makeStyles-disabled"]):focus': {
      color: theme.palette.text.invert,
      '&:before': {
        content: '""',
        position: 'absolute',
        top: theme.spacing(-2),
        bottom: theme.spacing(-2),
        background: theme.palette.background.focus,
        borderRadius: '50%',
      },
    },
  },
  navPrev: {
    marginRight: theme.spacing(6),
    '&:focus:before': {
      left: theme.spacing(-1.75),
      right: theme.spacing(-2.25),
    },
  },
  navNext: {
    marginLeft: theme.spacing(6),
    '&:not([class^="makeStyles-disabled"]):focus:before': {
      left: theme.spacing(-2.25),
      right: theme.spacing(-1.75),
    },
  },
  disabled: {
    visibility: 'hidden',
  },
  navIcon: {
    position: 'relative',
    fontSize: theme.spacing(6),
    '&.lgNavIcon': {
      fontSize: theme.spacing(11),
    },
  },
  galleryItemImage: {
    overflow: 'hidden',
    border: `${theme.palette.grey.light} solid 1px`,
    height: '260px',
    [theme.breakpoints.up('sm')]: {
      height: '160px',
    },
    [theme.breakpoints.up('md')]: {
      height: '145px',
    },
    [theme.breakpoints.up('lg')]: {
      height: '137px',
    },
  },
  galleryItemIntro: {
    fontSize: 18,
    fontWeight: 500,
    lineHeight: '23px',
    margin: 0,
    marginTop: theme.spacing(2),
  },
  mediaSlide: {
    border: 0,
    padding: 0,
    background: 'transparent',
    cursor: 'pointer',
    fontFamily: theme.typography.fontFamily,
    textAlign: 'left',
    width: '100%',
  },
}))

function MediaCarousel({ className, items, id }) {
  const { toggleOverlay } = useContext(ToggleComponentContext)
  const classes = useStyles({ calculateArrowMarginTop })

  function calculateArrowMarginTop(imageHeight, arrowSize) {
    return (imageHeight - arrowSize) / 2
  }

  function onSwiperSlideClick(index) {
    openDialog(items[index])
  }

  function openDialog(mediaItem) {
    if (mediaItem.printMaterial) {
      toggleOverlay(true, 'displayPrintMaterial', 'default', {
        trackingID: 'dialog',
        printMaterial: mediaItem.printMaterial,
        width: 'xl',
      })
    } else {
      toggleOverlay(true, 'displayMedia', 'default', {
        trackingID: 'dialog',
        mediaItem,
      })
    }
  }

  return (
    <div
      data-testid="mediaCarousel"
      className={clsx(className, classes.mainContainer)}
    >
      <div
        className={clsx(
          classes.navFraction,
          classes.navPrev,
          `swiper-prev-${id}`
        )}
      >
        <Icon className={classes.navIcon} name={'Prev'} viewBox={'0 0 24 47'} />
      </div>
      <Carousel
        settings={{
          breakpoints: {
            640: {
              slidesPerView: 2,
              spaceBetween: 24,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 28,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 32,
            },
          },
          watchOverflow: true,
          navigation: {
            nextEl: `.swiper-next-${id}`,
            prevEl: `.swiper-prev-${id}`,
            disabledClass: `${classes.disabled}`,
          },
        }}
        navigationOpts={{
          useExternal: true,
        }}
        onSlideClick={onSwiperSlideClick}
        className={clsx(classes.carouselRoot)}
      >
        {(() => {
          return items.map((item, index) => {
            let thumbnailFluid, alt, title
            if (item.printMaterial) {
              thumbnailFluid = item.printMaterial.images[0].fluid
              alt = item.printMaterial.images[0].description
              title = item.printMaterial.title
            } else {
              alt = item.description
              title = item.title
              if (!!item.thumbnail) {
                thumbnailFluid = {
                  ...item.thumbnail,
                  media: `(min-width: 0px)`,
                }
              } else {
                thumbnailFluid = {
                  aspectRatio: 1.7778,
                  src: `https://img.youtube.com/vi/${item.youTubeKey}/maxresdefault.jpg`,
                  srcSet: `https://img.youtube.com/vi/${item.youTubeKey}/maxresdefault.jpg`,
                  sizes: '',
                  media: `(min-width: 0px)`,
                }
              }
            }

            return (
              <button
                className={classes.mediaSlide}
                key={`media-slide-${index}`}
              >
                <div className={classes.galleryItemImage}>
                  <Img
                    fluid={thumbnailFluid}
                    alt={alt}
                    backgroundColor="grey"
                    objectFit={item.printMaterial ? 'contain' : 'cover'}
                  />
                </div>
                <p className={classes.galleryItemIntro}>{title}</p>
              </button>
            )
          })
        })()}
      </Carousel>
      <div
        className={clsx(
          classes.navFraction,
          classes.navNext,
          `swiper-next-${id}`
        )}
      >
        <Icon className={classes.navIcon} name={'Next'} viewBox={'0 0 24 47'} />
      </div>
    </div>
  )
}

MediaCarousel.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  items: PropTypes.array,
  navigation: PropTypes.object,
  breakpoints: PropTypes.object,
}

export default MediaCarousel
