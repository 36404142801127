import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import Headline from '@objects/headline'
import MediaCarousel from '@objects/mediaCarousel'
import MediaGrid from '@components/mediaGrid'
import { focalPointsTable } from '@tools/focalPoints'

import useDateFns from '@hooks/useDateFns'

const useStyles = makeStyles((theme) => ({
  root: {},
  headline: {
    margin: 0,
  },
  mediaGrid: {
    marginTop: theme.spacing(10),
  },
  mediaCarousel: {
    marginTop: theme.spacing(14),
    '& ~ &': {
      marginTop: theme.spacing(15),
    },
  },
  carouselTitle: {
    fontSize: '24px',
    lineHeight: '28px',
    marginTop: 0,
    marginBottom: theme.spacing(8),
    color: theme.palette.text.hint,
  },
}))

function MediaPageGallery({ className, title, mediaList }) {
  const classes = useStyles()

  function createFluidImageSrc(image) {
    if (!image?.details?.image) return null
    return {
      aspectRatio: image.details.image.width / image.details.image.height,
      src: image.url + '?w=800&q=90',
      srcSet: `
        ${image.url}?w=${Math.round(
        image.details.image.width / 4
      )}&&q=90 ${Math.round(image.details.image.width / 4)}w,
        ${image.url}?w=${Math.round(
        image.details.image.width / 2
      )}&&q=90 ${Math.round(image.details.image.width / 2)}w,
        ${image.url}?w=${image.details.image.width}&&q=90 ${
        image.details.image.width
      }w,
        ${image.url}?w=${Math.round(
        image.details.image.width * 1.5
      )}&&q=90 ${Math.round(image.details.image.width * 1.5)}w,
        ${image.url}?w=1000&&q=90 1000w
      `,
      sizes: '(max-width: 800px) 100vw, 800px',
    }
  }

  function createContentfulImageCarouselItem(item) {
    return {
      thumbnail:
        item.image?.fluid ||
        item.thumbnail?.fluid ||
        item.thumbnailSmall?.fluid,
      file: item.image?.file,
      alt: item.image?.description,
      description: item.intro?.intro,
    }
  }
  function createContentfulVideoCarouselItem(item) {
    return {
      thumbnail: createFluidImageSrc(item.thumbnail?.file),
      file: item.media?.file,
      alt: item.media?.description,
      description: item.boxCopy?.boxCopy,
    }
  }
  function createContentfulYoutubeCarouselItem(item) {
    let thumbnail = null
    if (item.thumbnail) {
      thumbnail = createFluidImageSrc(item.thumbnail?.file)
    } else {
      thumbnail = {
        aspectRatio: 1.7778,
        src: `https://img.youtube.com/vi/${item.youTubeKey}/maxresdefault.jpg`,
        srcSet: `https://img.youtube.com/vi/${item.youTubeKey}/maxresdefault.jpg`,
        sizes: '',
        media: `(min-width: 0px)`,
      }
    }

    return {
      thumbnail: thumbnail,
      alt: item.thumbnail?.description,
      description: item.intro?.intro,
      youTubeKey: item.youTubeKey,
      id: item.id,
    }
  }

  function isPrintMaterialValid(item) {
    return item.images?.length > 0 && item.images[0].fluid
  }

  function createCarouselItems(mediaListItem) {
    //Filter
    const { formatDate } = useDateFns()

    const filterItems = mediaListItem.files?.filter((item) => {
      if (item.__typename === 'ContentfulImage' && !!item.image?.file)
        return true
      else if (item.__typename === 'ContentfulVideo' && !!item.thumbnail?.file)
        return true
      else if (item.__typename === 'ContentfulYoutube' && !!item.youTubeKey)
        return true
      else if (
        item.__typename === 'ContentfulPrintMaterial' &&
        isPrintMaterialValid(item)
      )
        return true
      return false
    })
    //Mapping
    const items = filterItems?.map((item) => {
      let mappedItem
      if (item.__typename === 'ContentfulImage')
        mappedItem = createContentfulImageCarouselItem(item)
      else if (item.__typename === 'ContentfulVideo')
        mappedItem = createContentfulVideoCarouselItem(item)
      else if (item.__typename === 'ContentfulPrintMaterial') {
        mappedItem = {
          printMaterial: item,
        }
      } else mappedItem = createContentfulYoutubeCarouselItem(item)
      mappedItem.type = item.__typename
      mappedItem.id = item.id
      mappedItem.title = item.title
      mappedItem.createdAt = formatDate(item.createdAt, 'dd.MM.yyyy')
      mappedItem.copyright = item.copyright
      return mappedItem
    })

    return items
  }

  function renderInfographyList(infographyItem) {
    const { formatDate } = useDateFns()
    const files = infographyItem?.files?.map((item) => {
      let mappedItem
      if (item.__typename === 'ContentfulPrintMaterial') {
        mappedItem = {
          printMaterial: item,
        }
      } else {
        mappedItem = {
          thumbnail:
            item.image?.fluid ||
            item.thumbnail?.fluid ||
            item.thumbnailSmall?.fluid,
          file: item.image?.file || item.media?.file || null,
          description:
            item.image?.description || item.media?.description || null,
          youTubeKey: item.youTubeKey,
          id: item.id,
        }
      }

      mappedItem.createdAt = formatDate(item.createdAt, 'dd.MM.yyyy')
      mappedItem.type = item.__typename
      mappedItem.title = item.title
      mappedItem.trackingTitle = item.trackingTitle
      mappedItem.category = title
      return mappedItem
    })

    return <MediaGrid files={files} />
  }

  function renderCarouselList(item) {
    const carouselItems = createCarouselItems(item)
    return carouselItems && <MediaCarousel items={carouselItems} id={item.id} />
  }

  function renderMediaGrid() {
    // mediaList.length > 1 ? renderCarousel : renderInfographyList

    if (mediaList.length > 1) {
      return mediaList?.map((item) => {
        return (
          <div
            key={item.id}
            data-testid="mediaItem"
            className={classes.mediaCarousel}
          >
            <p className={classes.carouselTitle}>
              {item.title} ({item.amount})
            </p>
            {renderCarouselList(item)}
          </div>
        )
      })
    }
    return mediaList?.map((item) => {
      return (
        <div
          key={item.id}
          data-testid="mediaItem"
          className={classes.mediaGrid}
        >
          {renderInfographyList(item)}
        </div>
      )
    })
  }

  return (
    <div id={title.replace(/\s/g, '-').toLowerCase()} className={className}>
      <Headline className={classes.headline} level={3}>
        {title}
      </Headline>
      {renderMediaGrid()}
    </div>
  )
}

MediaPageGallery.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  mediaList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      thumbnail: PropTypes.shape({
        fluid: PropTypes.object,
      }),
      focalPoint: PropTypes.oneOf(Object.keys(focalPointsTable)),
      amount: PropTypes.number,
      files: PropTypes.array,
    })
  ),
}

export default MediaPageGallery
